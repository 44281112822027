<template>
  <div class="notifications">
    <el-dropdown
      trigger="click"
      placement="top-end"
      v-if="notifications.length > 0"
    >
      <a
        class="button is-icon has-text-primary el-dropdown-link notification-button"
      >
        <span
          class="icon-bell"
          :class="{ 'has-notifications': notifications.length > 0 }"
        ></span>
      </a>
      <el-dropdown-menu slot="dropdown" class="dropdown-notifications">
        <div
          class="notification-item"
          :class="{
            'is-start': item.type == 1,
            'is-stop': item.type == 2,
            'is-continue': item.type == 3
          }"
          v-for="(item, index) in notifications"
          :key="index"
          @click="openNotification(item)"
        >
          <span class="icon-type"></span>
          <span v-html="item.title"></span>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
    <a
      class="button is-icon has-text-primary el-dropdown-link notification-button"
      v-else
    >
      <span
        class="icon-bell"
        :class="{ 'has-notifications': notifications.length > 0 }"
      ></span>
    </a>
  </div>
</template>

<script>
export default {
  name: "Notifications",
  computed: {
    notifications() {
      return this.$store.getters["notifications/list"];
    }
  },
  methods: {
    openNotification(item) {
      this.axiosRequest("put", "read-notification/" + item.id)
        .then((response) => {
          console.log(response.data);
          this.$store.commit("notifications/deleteById", item.id);
          this.$router.push("/seed/" + item.card_id);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
};
</script>

<style lang="scss">
.notifications {
  .has-notifications {
    font-size: 1.5rem;
    &:after {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 6px;
      position: absolute;
      top: 10px;
      right: 8px;
      background: #00b69f;
      border: solid 1px #ffffff;
    }
  }

  .notification-button {
    &:hover {
      transform: rotate(20deg);
    }
  }
}
.dropdown-notifications {
  width: 450px;
  background: #e0e0e0;
  padding: 30px;

  @include ay-mobile {
    width: 100%;
    left: 0 !important;
  }

  .notification-item {
    height: 62px;
    border-left: solid 18px #000000;
    background: #ffffff;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 4px;

    .icon-type {
      margin: 0 12px;
    }

    &.is-start {
      border-color: #15b69f;

      .icon-type {
        color: #15b69f;
      }
    }

    &.is-stop {
      border-color: $danger-color;

      .icon-type {
        color: $danger-color;
      }
    }

    &.is-continue {
      border-color: $info-color;

      .icon-type {
        color: $info-color;
      }
    }
  }
}

.el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #e0e0e0;
}
</style>
